import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { httpApiPostText } from "../../../../lib";

const FormularioPropiedadesEmpresas = (props: any) => {
  let [data, setData] = useState(new Array());

  let columns = [
    {
      name: "Id",
      selector: (row: any) => row.idbusiness_group,
      sortable: true,
      grow: 5,
    },
    {
      name: "Descrpción",
      selector: (row: any) => row.name,
      sortable: true,
      grow: 10,
    },
    {
      name: "Estado",
      selector: (row: any) => (row.activo == 1 ? "Activado" : "Inactivo"),
      sortable: true,
      omit: false,
    },
  ];

  const pagOptions = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const preparaDatos = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "getSelectCompany" }
    );

    const datas = JSON.parse(response.message);
    const newData = new Array();
    datas.payload.map((dta: any) => {
      const obj = {
        ...dta,
      };
      newData.push(obj);
    });
    data = newData;
    setData([...data]);
  };

  useEffect(() => {
    preparaDatos();
  }, []);

  return (
    <form>
      <div className="mt-3">
        <label>Listado de Perfiles</label>
        <DataTable
          columns={columns}
          data={data}
          selectableRowsHighlight
          fixedHeader
          pagination
          highlightOnHover
          paginationComponentOptions={pagOptions}
        />
      </div>
    </form>
  );
};

export default FormularioPropiedadesEmpresas;
