import { initalState } from "./Reducer";

export const SetEntornoEmp = (em:any) =>{
    return {type:"SetEntornoEmp", emp: em};
};
export const USER_LOGOUT = () =>{
    return {type:"USER_LOGOUT", emp: initalState, company: initalState.company};
};

export const GetEntornoEmp = () =>{
    return {type:"GetEntornoEmp"};
};

export const GET_COMPANY_LOGO = (company: string) =>{
    return  {type:"GET_COMPANY_LOGO", company: company};
};

