import DataTable from "react-data-table-component";
import BarraMenu from "../../../components/BarraMenu";
import { useEffect, useState } from "react";
import { httpApiPostText } from "../../../lib";
import { FaPencilAlt, FaUndoAlt } from "react-icons/fa";
import MsgDialog from "../../../components/MsgDialog";
import sha256 from "crypto-js/sha256";

const userResponse = {
  id_user: 0,
  name: "",
  profile: "",
  client: "",
  states: "",
  user_registered: "",
  user_email: "",
};

function validateEmail(email: string) {
  // Define our regular expression.
  let validEmail = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Using test we can check if the text match the pattern
  if (validEmail.test(email)) {
    return true;
  } else {
    return false;
  }
}

const ColaboradoresPage = () => {
  let [data, setData] = useState(new Array());
  let [cpData, setCpData] = useState(new Array());
  let [perfil, setPerfil] = useState(new Array());
  let [cargo, setCargo] = useState(new Array());
  let [clientes, setClientes] = useState(new Array());
  let [companies, setCompanies] = useState(new Array());
  let [evaluators, setEvaluators] = useState(new Array());
  let [formData, setFormData] = useState({
    id_user: "",
    nombres: "",
    apellidos: "",
    cargo: "",
    cliente: "",
    fingreso: "",
    fUltimaEval: "",
    email: "",
    perfil: "",
    evaluador: "",
    empresa: "",
  });
  const [borrarShow, setBorrarShow] = useState(false);
  const [respuestaShow, setRespuestaShow] = useState(false);
  const [respuestaMsg, setRespuestaMsg] = useState("");
  const [userSelected, setUserSelected] = useState(userResponse);
  const [msgActivacion, setMsgActivacion] = useState("");
  const [clnt, setClnt] = useState(false);
  const [evalua, setEvalua] = useState(false);
  const [title, setTitle] = useState(" Guardar ");
  const [evalText, setEvalText] = useState("");

  let columns = [
    {
      name: "Acciones",
      selector: (row: any) => row.Acciones,
      right: true,
      width: "100px",
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row: any) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Cargo",
      selector: (row: any) => row.cargo,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Perfíl",
      selector: (row: any) => row.profile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Correo",
      selector: (row: any) => row.user_email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Cliente",
      selector: (row: any) => row.client,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Fecha Ingreso",
      selector: (row: any) => row.user_registered,
      width: "200px",
      sortable: true,
    },
    {
      name: "Fecha evaluación",
      selector: (row: any) => row.user_evaluation_date,
      width: "200px",
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row: any) => row.states,
      sortable: true,
      width: "100px",
    },
  ];

  const pagOptions = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const conditionalRowStyles = [
    {
      when: (row: { states: string }) => row.states === "inactivo",
      style: {
        backgroundColor: "#D5D5D5",
      },
    },
  ];

  function sumarDias(fecha: any, dias: number) {
    let dia = parseInt(fecha.substring(8, 10)) + 1;
    let mes = fecha.substring(5, 7);
    let anno = fecha.substring(0, 4);
    let fec = `${anno}-${mes}-${dia}`;
    const newFecha = new Date(fec);
    newFecha.setDate(newFecha.getDate() + dias);

    fec = newFecha.toLocaleDateString();
    let diaa =
      newFecha.getDate() + 1 < 10
        ? `0${newFecha.getDate() + 1}`
        : `${newFecha.getDate() + 1}`;
    let mesa =
      newFecha.getMonth() + 1 < 10
        ? `0${newFecha.getMonth() + 1}`
        : `${newFecha.getMonth() + 1}`;
    let annoa = newFecha.getFullYear();
    fec = `${annoa}-${mesa}-${diaa}`;

    return fec;
  }

  const getUsers = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "allUser" }
    );
    const dta = JSON.parse(response.message);
    const newData = new Array();
    dta.payload.map((dta: any) => {
      const obj = {
        ...dta,
        Acciones: (
          <div className=" d-flex gap-3">
            <a
              href="#inicio"
              id={`${dta.id_user}`}
              onClick={() => {
                editarUsario(dta.id_user);
              }}
            >
              <FaPencilAlt
                title="Editar"
                color={"white"}
                className="bg-warning rounded-circle p-1 h4"
              />
            </a>
            <a
              href="#!"
              id={`${dta.id_user}`}
              onClick={() => {
                desactivaActvaUser(dta);
              }}
            >
              <FaUndoAlt
                title="Cambia de estado"
                color={"white"}
                className="bg-danger rounded-circle p-1 h4"
              />
            </a>
          </div>
        ),
      };
      newData.push(obj);
    });
    data = [...newData];
    setData([...data]);
    cpData = [...newData];
    setCpData([...cpData]);
  };

  const getPerfil = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "getSelectProfiles" }
    );
    const dta = JSON.parse(response.message);
    setPerfil(dta.payload);
  };

  const getCliente = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "getSelectClients" }
    );
    const dta = JSON.parse(response.message);
    setClientes(dta.payload);
  };
  const getCompanies = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "getSelectCompany" }
    );
    const dta = JSON.parse(response.message);
    const companies = dta.payload.filter((item: any) => item.activo == "1");
    setCompanies(companies);
  };

  const getEvaluators = async () => {
    const bdy = {
      valid: "selectEvaluators",
    };
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      bdy
    );
    const dta = JSON.parse(response.message);
    setEvaluators(dta.payload);
  };

  const editarUsario = async (id: any) => {
    const bdy = {
      valid: "selectUser",
      id_user: `${id}`,
    };
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      bdy
    );
    const dta = JSON.parse(response.message);
    formData = {
      id_user: id,
      nombres: dta.payload[0].first_name,
      apellidos: dta.payload[0].last_name,
      cargo: dta.payload[0].cargo,
      cliente: dta.payload[0].client,
      fingreso: dta.payload[0].user_registered.substring(0, 10),
      fUltimaEval: dta.payload[0].user_evaluation_date.substring(0, 10),
      email: dta.payload[0].user_email,
      perfil: dta.payload[0].user_profile,
      evaluador: dta.payload[0].id_evaluator,
      empresa: dta.payload[0].idbusiness_group,
    };
    setFormData({ ...formData });
    if (formData.perfil.toString() === "4") {
      setEvalua(false);
    } else {
      setEvalua(true);
    }
    setTitle(" Actualizar ");
    getUsers();
  };

  const desactivaActvaUser = (user: any) => {
    setUserSelected(user);
    setMsgActivacion(
      user.states === "activo"
        ? `Usted va desactivar al usuario ${user.name}, ¿Está usted seguro de querer hacerlo?`
        : `Usted va acitivar al usuario ${user.name}, ¿Está usted seguro de querer hacerlo?`
    );
    setBorrarShow(true);
  };

  const borrarNo = () => {
    setBorrarShow(false);
  };

  const borrarSi = async () => {
    setBorrarShow(false);

    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      {
        valid: "deleteUser",
        id_user: `${userSelected.id_user}`,
      }
    );

    const dta = JSON.parse(response.message);
    setRespuestaMsg(dta.message);
    setRespuestaShow(true);
    // actualiza la grilla
    getUsers();
  };

  const changeTextFiltro = (evnt: any) => {
    if (evnt.target.value.trim() === "") {
      setData([...cpData]);
    } else {
      let reg = cpData.filter((dato: any) => {
        return (
          dato?.name.toUpperCase().includes(evnt.target.value.toUpperCase()) ||
          dato?.job_user
            .toUpperCase()
            .includes(evnt.target.value.toUpperCase()) ||
          dato?.profile
            .toUpperCase()
            .includes(evnt.target.value.toUpperCase()) ||
          dato?.user_email
            .toUpperCase()
            .includes(evnt.target.value.toUpperCase()) ||
          dato?.client
            .toUpperCase()
            .includes(evnt.target.value.toUpperCase()) ||
          dato?.user_registered
            .toUpperCase()
            .includes(evnt.target.value.toUpperCase()) ||
          dato?.states.toUpperCase().includes(evnt.target.value.toUpperCase())
        );
      });
      setData([...reg]);
    }
  };

  const select = async (evnt: any) => {
    formData = { ...formData, [evnt.target.id]: evnt.target.value };
    setFormData({ ...formData });
  };

  const textChange = async (evnt: any) => {
    formData = { ...formData, [evnt.target.id]: evnt.target.value };
    setFormData({ ...formData });
  };

  const guardar = async (e: any) => {
    e.preventDefault();

    if (formData.fingreso === "") {
      formData = { ...formData, fUltimaEval: "" };
    } else {
      formData = {
        ...formData,
        fUltimaEval: sumarDias(formData.fingreso, 365),
      };
    }

    setFormData({ ...formData });

    if (title === " Guardar ") {
      // Guardar
      let sw: number = 0;

      if (!validateEmail(formData.email)) {
        setRespuestaMsg("El email no es válido. Por favor revisar");
        setRespuestaShow(true);
      } else {
        if (evalText.toLowerCase() === "administrador") {
          sw = formData.nombres.trim() === "" ? ++sw : sw;
          sw = formData.apellidos.trim() === "" ? ++sw : sw;
          sw = formData.email.trim() === "" ? ++sw : sw;
          sw = !validateEmail(formData.email) ? ++sw : sw;
          sw = formData.perfil === "" ? ++sw : sw;
          sw = formData.cargo === "" ? ++sw : sw;
          sw = formData.fingreso === "" ? ++sw : sw;

          setRespuestaMsg(
            "Los campos habilitados deben ser diligenciados y deben contener información válida, como el correo electrónico. Por favor revisar"
          );
        } else if (evalText.toLowerCase() === "evaluador") {
          sw = formData.nombres.trim() === "" ? ++sw : sw;
          sw = formData.apellidos.trim() === "" ? ++sw : sw;
          sw = formData.email.trim() === "" ? ++sw : sw;
          sw = !validateEmail(formData.email) ? ++sw : sw;
          sw = formData.perfil === "" ? ++sw : sw;
          sw = formData.cargo === "" ? ++sw : sw;
          sw = formData.cliente === "" ? ++sw : sw;
          sw = formData.fingreso === "" ? ++sw : sw;

          setRespuestaMsg(
            "Los campos habilitados deben ser diligenciados y deben contener información válida, como el correo electrónico. Por favor revisar"
          );
        } else {
          sw = formData.nombres.trim() === "" ? ++sw : sw;
          sw = formData.apellidos.trim() === "" ? ++sw : sw;
          sw = formData.email.trim() === "" ? ++sw : sw;
          sw = !validateEmail(formData.email) ? ++sw : sw;
          sw = formData.perfil === "" ? ++sw : sw;
          sw = formData.cargo === "" ? ++sw : sw;
          sw = formData.cliente === "" ? ++sw : sw;
          sw = formData.evaluador === "" ? ++sw : sw;
          sw = formData.fingreso === "" ? ++sw : sw;

          setRespuestaMsg(
            "Los campos habilitados deben ser diligenciados y deben contener información válida, como el correo electrónico. Por favor revisar"
          );
        }

        if (sw === 0) {
          const pwdSHA256 = sha256(formData.email).toString();
          const bdy = {
            user_login: formData.email,
            user_pass: pwdSHA256,
            user_email: formData.email,
            first_name: formData.nombres,
            last_name: formData.apellidos,
            user_profile: formData.perfil,
            user_job: formData.cargo,
            id_client: formData.cliente,
            user_registered: formData.fingreso,
            user_evaluation_date: formData.fUltimaEval,
            id_evaluator: formData.evaluador,
            valid: "newUser",
            idbusiness_group: formData.empresa,
          };
          const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
          const response = await httpApiPostText(
            url,
            "POST",
            {
              "Content-Type": "application/json",
            },
            bdy
          );
          const dta = JSON.parse(response.message);
          setRespuestaMsg(dta.message);
          setRespuestaShow(true);

          if (dta.code === 1) {
            formData = {
              id_user: "",
              nombres: "",
              apellidos: "",
              cargo: "0",
              cliente: "0",
              fingreso: "",
              fUltimaEval: "",
              email: "",
              perfil: "",
              evaluador: "",
              empresa: "",
            };
            setFormData({ ...formData });

            getUsers();
            getEvaluators();
            setTitle(" Guardar ");
          }
        } else {
          setRespuestaShow(true);
        }
      }
    } else {
      // Actualizar
      let sw: number = 0;

      if (!validateEmail(formData.email)) {
        setRespuestaMsg("El email no es válido. Por favor revisar");
        setRespuestaShow(true);
      } else {
        if (evalText.toLowerCase() === "administrador") {
          sw = formData.nombres.trim() === "" ? ++sw : sw;
          sw = formData.apellidos.trim() === "" ? ++sw : sw;
          sw = formData.email.trim() === "" ? ++sw : sw;
          sw = !validateEmail(formData.email) ? ++sw : sw;
          sw = formData.perfil === "" ? ++sw : sw;
          sw = formData.cargo === "" ? ++sw : sw;
          sw = formData.fingreso === "" ? ++sw : sw;

          setRespuestaMsg(
            "Los campos habilitados deben ser diligenciados y deben contener información válida, como el correo electrónico. Por favor revisar"
          );
        } else if (evalText.toLowerCase() === "evaluador") {
          sw = formData.nombres.trim() === "" ? ++sw : sw;
          sw = formData.apellidos.trim() === "" ? ++sw : sw;
          sw = formData.email.trim() === "" ? ++sw : sw;
          sw = !validateEmail(formData.email) ? ++sw : sw;
          sw = formData.perfil === "" ? ++sw : sw;
          sw = formData.cargo === "" ? ++sw : sw;
          sw = formData.cliente === "" ? ++sw : sw;
          sw = formData.fingreso === "" ? ++sw : sw;

          setRespuestaMsg(
            "Los campos habilitados deben ser diligenciados y deben contener información válida, como el correo electrónico. Por favor revisar"
          );
        } else {
          sw = formData.nombres.trim() === "" ? ++sw : sw;
          sw = formData.apellidos.trim() === "" ? ++sw : sw;
          sw = formData.email.trim() === "" ? ++sw : sw;
          sw = !validateEmail(formData.email) ? ++sw : sw;
          sw = formData.perfil === "" ? ++sw : sw;
          sw = formData.cargo === "" ? ++sw : sw;
          sw = formData.cliente === "" ? ++sw : sw;
          sw = formData.evaluador === "" ? ++sw : sw;
          sw = formData.fingreso === "" ? ++sw : sw;

          setRespuestaMsg(
            "Los campos habilitados deben ser diligenciados y deben contener información válida, como el correo electrónico. Por favor revisar"
          );
        }

        if (sw === 0) {
          const bdy = {
            id_user: formData.id_user,
            user_login: formData.email,
            user_pass: "",
            user_email: formData.email,
            first_name: formData.nombres,
            last_name: formData.apellidos,
            user_profile: formData.perfil,
            user_client: formData.cliente,
            user_registered: formData.fingreso,
            user_evaluation_date: formData.fUltimaEval,
            valid: "updateUser",
            user_job: formData.cargo,
            id_client: formData.cliente === "" ? 0 : formData.cliente,
            id_evaluator: formData.evaluador === "" ? 0 : formData.evaluador,
            idbusiness_group: formData.empresa === "" ? 0 : formData.empresa,
          };
          const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
          const response = await httpApiPostText(
            url,
            "PUT",
            {
              "Content-Type": "application/json",
            },
            bdy
          );
          const dta = JSON.parse(response.message);

          setRespuestaMsg(dta.message);
          setRespuestaShow(true);

          formData = {
            id_user: "",
            nombres: "",
            apellidos: "",
            cargo: "0",
            cliente: "0",
            fingreso: "",
            fUltimaEval: "",
            email: "",
            perfil: "",
            evaluador: "",
            empresa: "",
          };
          setFormData({ ...formData });

          getUsers();
          getPerfil();
          getCliente();
          getCargo();
          getCompanies();
          getEvaluators();
          setTitle(" Guardar ");
        } else {
          setRespuestaShow(true);
        }
      }
    }
  };

  const cancelar = async () => {
    formData = {
      id_user: "",
      nombres: "",
      apellidos: "",
      cargo: "0",
      cliente: "0",
      fingreso: "",
      fUltimaEval: "",
      email: "",
      perfil: "",
      evaluador: "",
      empresa: "",
    };
    setFormData({ ...formData });
    setTitle(" Guardar ");
  };

  const getCargo = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "getSelectUserJob" }
    );
    const dta = JSON.parse(response.message);
    setCargo(dta.payload);
  };

  const onSelectPerfil = (evnt: any) => {
    formData = {
      ...formData,
      [evnt.target.id]: evnt.target.value,
      cliente: "",
      evaluador: "",
    };
    setFormData({ ...formData });
    let index = evnt.target.selectedIndex;
    let selected = evnt.target.options[index].text;
    setEvalText(selected.toLowerCase().trim());

    if (selected.toLowerCase().trim() === "administrador") {
      setClnt(true);
      setEvalua(true);
    } else if (selected.toLowerCase().trim() === "evaluador") {
      setEvalua(true);
      setClnt(false);
    } else {
      setClnt(false);
      setEvalua(false);
    }
  };

  useEffect(() => {
    getUsers();
    getPerfil();
    getCliente();
    getCompanies();
    getCargo();
    getEvaluators();
  }, []);

  return (
    <>
      <div className="container bg-white">
        <BarraMenu />
        <a className="text-white-50" href="/" id="inicio">
          Inicio
        </a>
        <div>
          <div className="text-secondary ms-3 mt-3 fw-bold">
            Home / Gestión / Creación de clientes , colaboradores y
            administrativos
          </div>
        </div>

        <form
          className="row g-3 rounded shadow mt-3 mb-3"
          style={{ backgroundColor: "#DCDCDC" }}
        >
          <div className="col-md-6">
            <label htmlFor="nombres" className="form-label">
              Nombre
            </label>
            <input
              type="text"
              className="form-control"
              id="nombres"
              value={formData.nombres}
              placeholder="Nombre"
              onChange={textChange}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="apellidos" className="form-label">
              Apellidos
            </label>
            <input
              type="text"
              className="form-control"
              id="apellidos"
              value={formData.apellidos}
              placeholder="Apellidos"
              onChange={textChange}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              value={formData.email}
              placeholder="email"
              onChange={textChange}
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="fUltimaEval" className="form-label">
              Cargo
            </label>
            <select
              id="cargo"
              className="form-select"
              onChange={select}
              value={formData.cargo}
            >
              <option value="">Seleccione un cargo...</option>
              {cargo.map((dt: any, index: number) => (
                <option value={dt.id} key={dt.id}>
                  {dt.description}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="cargo" className="form-label">
              Perfíl
            </label>
            <select
              id="perfil"
              className="form-select"
              onChange={onSelectPerfil}
              value={formData.perfil}
            >
              <option value="">Seleccione un perfil...</option>
              {perfil.map((dt: any, index: number) => (
                <option value={dt.id} key={dt.id}>
                  {dt.description}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="cliente" className="form-label">
              Cliente
            </label>
            <select
              id="cliente"
              className="form-select"
              onChange={select}
              value={formData.cliente}
              disabled={clnt}
            >
              <option value="">Seleccione un cliente...</option>
              {clientes.map((dt: any, index: number) => (
                <option value={dt.id} key={dt.id}>
                  {dt.description}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="empresa" className="form-label">
              Empresa
            </label>
            <select
              id="empresa"
              className="form-select"
              onChange={select}
              value={formData.empresa}
              disabled={clnt}
            >
              <option value="">Seleccione una empresa...</option>
              {companies.map((dt: any, index: number) => (
                <option value={dt.idbusiness_group} key={index}>
                  {dt.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-6">
            <label htmlFor="fingreso" className="form-label">
              Fecha de ingreso
            </label>
            <input
              type="date"
              className="form-control"
              id="fingreso"
              value={formData.fingreso}
              onChange={textChange}
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="fUltimaEval" className="form-label">
              Última evaluación
            </label>
            <input
              type="date"
              className="form-control"
              id="fUltimaEval"
              value={formData.fUltimaEval}
              onChange={textChange}
              disabled
            />
          </div>
          <div className="col-lg-6">
            <label htmlFor="fUltimaEval" className="form-label">
              Lider / Evaluador
            </label>
            <select
              id="evaluador"
              className="form-select"
              onChange={select}
              value={formData.evaluador}
              disabled={evalua}
            >
              <option value="">Seleccione un lider / evaluador...</option>
              {evaluators.map((dt: any, index: number) => (
                <option value={dt.id_user} key={dt.id_user}>
                  {dt.evaluator}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className="col-lg-12 d-flex justify-content-end p-3 gap-3">
              <button
                type="button"
                className="btn w-25 text-light"
                style={{ backgroundColor: "#8FC347" }}
                onClick={cancelar}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn w-25 text-light"
                style={{ backgroundColor: "#8FC347" }}
                onClick={guardar}
              >
                {title}
              </button>
            </div>
          </div>
        </form>

        <div
          className="row shadow rounded mt-3 mb-3"
          style={{ backgroundColor: "#DCDCDC" }}
        >
          <div className="d-flex flex-column col col-lg-3 m-3 ">
            <label htmlFor="txtBuscar" className="m-1">
              Busqueda
            </label>
            <input
              type="text"
              id="txtBuscar"
              className="form-control m-1"
              onChange={changeTextFiltro}
            />
          </div>
          <div className="mb-3">
            <DataTable
              columns={columns}
              data={data}
              selectableRowsHighlight
              fixedHeader
              pagination
              highlightOnHover
              paginationComponentOptions={pagOptions}
              conditionalRowStyles={conditionalRowStyles}
              className="border shadow rounded"
            />
          </div>
        </div>
      </div>
      {/*********** seccion de modales **********************/}
      {/************Modal cosnulta para desactivar colaboradores**********/}
      <MsgDialog
        Show={borrarShow}
        Title={`Gestión Colaboradores`}
        Message={msgActivacion}
        BtnOkName="Sí, continuar"
        BtnNokName="No, cancelar"
        HandlerClickOk={() => borrarSi()}
        HanlerdClickNok={borrarNo}
      />

      {/************Modal de respuesdta a la acción realizada**********/}
      <MsgDialog
        Show={respuestaShow}
        Title={`Gestión Colaboradores`}
        Message={respuestaMsg}
        BtnOkName="Aceptar"
        BtnNokName=""
        HandlerClickOk={() => setRespuestaShow(false)}
        HanlerdClickNok={null}
      />
    </>
  );
};

export default ColaboradoresPage;
