import BarraMenu from "../../../components/BarraMenu";
import { useState } from "react";
import { httpApiPostText } from "../../../lib";
import MsgDialog from "../../../components/MsgDialog";
import sha256 from "crypto-js/sha256";
import { Alert } from "react-bootstrap";
import { FaRegHandPaper } from "react-icons/fa";
import "./style.css"

const AdministracionUsuariosExternosPage = () => {
  const [formData, setFormData] = useState({
    user_correo: "",
    user_pass: "",
    user_conf_pass: "",
  });
  const [msgDlgShow, setMsgDlgShow] = useState(false);
  const [msgMensaje, setMsgMensaje] = useState("");
  const [msgDlgShowError, setMsgDlgShowError] = useState(false);
  const [msgMensajeError, setMsgMensajeError] = useState("");

  const textChange = (event: any) => {

    const passRegex = /^(?=.*?[#?!@$%^&*-])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if(event.target.id == "pass"){
      if(passRegex.test(event.target.value)){
        const formDataNew = { ...formData, [event.target.id]: event.target.value.trim()};
        setFormData(formDataNew);

      }else{
        const formDataNew = { ...formData, [event.target.id]: event.target.value.trim()};
        setFormData(formDataNew);
      }
    }else{
      let formDataNew = { ...formData, [event.target.id]: event.target.value };
      setFormData({ ...formDataNew });
    }

  };

  const guardar = async (e: any) => {
    e.preventDefault();

    let nObj = { ...formData, valid: "updatePass" };

    let sw = 0;

    sw = formData.user_correo === "" ? ++sw : 0;
    sw = formData.user_pass === "" ? ++sw : 0;
    sw = formData.user_conf_pass === "" ? ++sw : 0;

    if (sw === 0) {
      if (formData.user_pass === formData.user_conf_pass) {
        const pwdSHA256 = sha256(formData.user_pass).toString();
        nObj = { ...nObj, user_pass: pwdSHA256 };

        const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
        const response = await httpApiPostText(
          url,
          "PUT",
          {
            "Content-Type": "application/json",
          },
          nObj
        );
        const dta = JSON.parse(response.message);
        setMsgMensaje(dta.message);
        setMsgDlgShow(true);

        const formDataNew = {
          user_correo: "",
          user_pass: "",
          user_conf_pass: "",
        };
        setFormData(formDataNew);
      } else {
        setMsgMensajeError("Las contraseñas no coinciden");
        setMsgDlgShowError(true);
      }
    } else {
      setMsgMensajeError("Los campos son obligatorios");
      setMsgDlgShowError(true);
    }
  };

  return (
    <>
      <div className="container bg-white">
        <BarraMenu />
        <div>
          <div className="text-secondary ms-3 mt-3 fw-bold">
            Home / Administración / Contraseñas de usuarios
          </div>
        </div>
        <form
          className="row g-3 rounded shadow mt-3 mb-3"
          style={{ backgroundColor: "#DCDCDC" }}
        >
          <div className="container__password">
          <div className="col-12 mb-2">
            <label htmlFor="nombre" className="form-label">
              Correo electrónico
            </label>
            <input
              type="email"
              className="form-control"
              id="user_correo"
              placeholder="Correo del usuario"
              onChange={textChange}
              value={formData.user_correo}
            />
          </div>

          <div className="col-12">
            <label htmlFor="nombre" className="form-label">
              Nueva contraseña
            </label>
            <input
              type="password"
              className="form-control"
              id="user_pass"
              placeholder="Nueva contraseña"
              onChange={textChange}
              value={formData.user_pass}
            />
            <Alert  variant="info" show={true} className="p-1 mt-1">
              <FaRegHandPaper className="mb-1" /> La contraseña debe cumplir con: Mínimo 8 dígitos, una mayúscula, una minúscula, un carácter especial y un número.
            </Alert>
          </div>

          <div className="col-12">
            <label htmlFor="nombre" className="form-label">
              Confirmación contraseña
            </label>
            <input
              type="password"
              className="form-control"
              id="user_conf_pass"
              placeholder="Confirme nueva contraseña"
              onChange={textChange}
              value={formData.user_conf_pass}
            />
          </div>

          <div className="col-lg-12 d-flex justify-content-end p-3">
            <button
              type="submit"
              className="btn w-25 text-light"
              style={{ backgroundColor: "#8FC347" }}
              onClick={guardar}
            >
              Guardar
            </button>
          </div>
          </div>

        </form>
      </div>
      {/*********** seccion de modales **********************/}
      {/*********** cuadro de dialogo para errores **********/}
      <MsgDialog
        Show={msgDlgShow}
        Title={`Cambio de contraseña`}
        Message={msgMensaje}
        BtnOkName="Aceptar"
        BtnNokName=""
        HandlerClickOk={() => setMsgDlgShow(false)}
        HanlerdClickNok={null}
      />

      <MsgDialog
        Show={msgDlgShowError}
        Title={`Cambio de contraseña`}
        Message={msgMensajeError}
        BtnOkName="Aceptar"
        BtnNokName=""
        HandlerClickOk={() => setMsgDlgShowError(false)}
        HanlerdClickNok={null}
      />
    </>
  );
};

export default AdministracionUsuariosExternosPage;
