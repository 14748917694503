import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { httpApiPostText } from "../../../../lib";
import { FaTrashAlt } from "react-icons/fa";

const FormularioPropiedadesEstados = (props: any) => {
  const [form, setForm] = useState({ texto: "" });
  let [data, setData] = useState(new Array());

  let columns = [
    { name: "Id", selector: (row: any) => row.id, sortable: true, grow: 5 },
    {
      name: "Descrpción",
      selector: (row: any) => row.description,
      sortable: true,
      grow: 10,
    },
    {
      name: "Estado",
      selector: (row: any) => row.status,
      sortable: true,
      grow: 10,
      omit: false,
    },
    { name: "Acciones", selector: (row: any) => row.Acciones, grow: 3 },
  ];

  const pagOptions = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const conditionalRowStyles = [
    {
      when: (row: { status: string }) => row.status === "Inactivo",
      style: {
        backgroundColor: "#D6D5D5",
      },
    },
  ];

  const onTextChange = (event: any) => {
    const formNew = { ...form, [event.target.id]: event.target.value };
    setForm({ ...formNew });
  };

  const onButtonClickAgregar = async (event: any) => {
    event.preventDefault();
    if (form.texto.trim() !== "") {
      const dt = {
        valid: "newStates",
        description: form.texto,
      };

      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      await httpApiPostText(
        url,
        "POST",
        {
          "Content-Type": "application/json",
        },
        dt
      );
      preparaDatos();
      const formDatos = { texto: "" };
      setForm(formDatos);
    }
  };

  const borraPerfil = async (id: any) => {
    console.log(id);
    const dt = {
      valid: "updateStates",
      id: id,
    };

    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    await httpApiPostText(
      url,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      dt
    );
    preparaDatos();
  };

  const preparaDatos = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "selectStates" }
    );
    const datas = JSON.parse(response.message);
    const newData = new Array();
    datas.payload.map((dta: any) => {
      const obj = {
        ...dta,
        Acciones: (
          <div className=" d-flex gap-1">
            <a
              href="#!"
              id={`${dta.id}`}
              onClick={() => {
                borraPerfil(dta.id);
              }}
            >
              <FaTrashAlt
                title="Editar"
                color={"white"}
                className="bg-danger rounded-circle p-1 h4"
              />
            </a>
          </div>
        ),
      };
      newData.push(obj);
    });
    data = newData;
    setData([...data]);
  };

  useEffect(() => {
    preparaDatos();
  }, []);

  return (
    <form>
      <div className="row ">
        <div className="col col-lg-10 ">
          <label htmlFor="texto" className="form-label">
            Descripción
          </label>
          <input
            type="text"
            className="form-control "
            id="texto"
            onChange={onTextChange}
            value={form.texto}
          />
        </div>
        <div className=" col-lg-2 d-flex align-items-center ">
          <Button
            type="submit"
            className="bg-dark"
            onClick={onButtonClickAgregar}
          >
            {" "}
            Agregar{" "}
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <p>Listado de Perfiles</p>
        <DataTable
          columns={columns}
          data={data}
          conditionalRowStyles={conditionalRowStyles}
          selectableRowsHighlight
          fixedHeader
          pagination
          highlightOnHover
          paginationComponentOptions={pagOptions}
        />
      </div>
    </form>
  );
};

export default FormularioPropiedadesEstados;
