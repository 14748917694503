import "react-calendar/dist/Calendar.css";
import BarraMenu from "../../../components/BarraMenu";
import Calendar from "react-calendar";
import { useEffect, useState } from "react";
import { httpApiPostText } from "../../../lib";

const ProximasEvaluacionesPage = () => {
  const [fechaSel, setFechaSel] = useState();
  let [dates, setDates] = useState(new Array());
  let [colaboradores, setColaboradores] = useState(new Array());

  const getMonthDates = async (month: any, year: any) => {
    const bdy = {
      month: `${month}`,
      year: `${year}`,
      valid: "userDateEvaluationMonth",
    };

    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      bdy
    );
    const dta = JSON.parse(response.message);
    dates = [];
    dta.payload.map((fecha: any) => {
      const dia: string = fecha.user_evaluation_date.substring(8, 10);
      const mes: string = fecha.user_evaluation_date.substring(5, 7);
      const ano: string = fecha.user_evaluation_date.substring(0, 4);

      const fec = `${dia}/${mes}/${ano}`;
      dates.push(fec);
    });
    setDates([...dates]);
  };

  const getDayDates = async (day: any, month: any, year: any) => {
    const bdy = {
      month: month,
      year: year,
      day: day,
      valid: "userDayEvaluation",
    };
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      bdy
    );
    const dta = JSON.parse(response.message);
    let users = new Array();
    dta.payload.map((obj: any) => {
      const fec = `${obj.name} -  ${obj.description}`;
      users.push(fec);
    });
    setColaboradores([...users]);
  };

  const getMonthYear = async (mes: number, anno: number) => {
    const bdy = {
      month: mes,
      year: anno,
      valid: "userDayMonthEvaluation",
    };
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      bdy
    );
    const dta = JSON.parse(response.message);
    dates = [];
    dta.payload.map((fecha: any) => {
      const dia: string = fecha.dates.substring(8, 10);
      const mes: string = fecha.dates.substring(5, 7);

      const fec = `${dia}/${mes}/${anno}`;
      dates.push(fec);
    });
    setDates([...dates]);
  };

  const cambiaMes = (date: any) => {
    const fecha = new Date(date);
    const mes = fecha.getMonth() + 1;
    const anno = fecha.getFullYear();
    getMonthYear(mes, anno);
  };

  useEffect(() => {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    getMonthDates(month, year);
  }, []);

  return (
    <>
      <div className="container bg-white">
        <BarraMenu />
        <div>
          <div className="text-dark ms-3 mt-3 fw-bold">
            Home / Evaluaciones / Próximas evaluaciones
          </div>
        </div>
        <div className="d-flex flex-row shadow rounded mt-3 mb-3">
          <div className=" m-2 " style={{ width: "70%" }}>
            <Calendar
              className="w-100 rounded "
              onViewChange={(vista: {
                action: any;
                activeStartDate: any;
                value: any;
                view: any;
              }) =>
                console.log(
                  "Al cambiar de modo de vista del calendario : ",
                  vista
                )
              }
              onActiveStartDateChange={({
                action,
                activeStartDate,
                value,
                view,
              }) => {
                cambiaMes(activeStartDate);
              }}
              onChange={(e: any) => {
                setFechaSel(e);
                const date = new Date(e);
                const dia = date.getDate();
                const mes = date.getMonth() + 1;
                const anno = date.getFullYear();

                getDayDates(dia, mes, anno);
              }}
              value={fechaSel}
              tileClassName={(e: any) => {
                const dia: string = new Date(e.date)
                  .getDate()
                  .toString()
                  .padStart(2, "0");
                const mes: string = (new Date(e.date).getMonth() + 1)
                  .toString()
                  .padStart(2, "0");
                const ano: string = new Date(e.date).getFullYear().toString();

                const d: string = `${dia}/${mes}/${ano}`;

                return dates.findIndex((f: string) => f === d) >= 0
                  ? "bg-success"
                  : null;
              }}
            />
          </div>

          <div
            className=" m-2 bg-light rounded"
            style={{ width: "30%", overflowY: "visible" }}
          >
            <p className="form-label fw-bold m-2">Colaboradores</p>
            <div style={{ height: "250px", overflowY: "auto" }}>
              {colaboradores.length > 0 ? (
                <ul>
                  {colaboradores.map((dato: string) => (
                    <li key={dato}>{dato}</li>
                  ))}
                </ul>
              ) : (
                <div className="container text-wrap">
                  {" "}
                  No hay evaluaciones programadas para este día{" "}
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div
                className="p-2 rounded"
                style={{ backgroundColor: "#fbfb73", minWidth: "170px" }}
              >
                <p className="h6 text-black">
                  <strong>Fecha actual</strong>
                </p>
              </div>
              <div>&nbsp;</div>
              <div
                className="p-2 bg-success  rounded"
                style={{ minWidth: "170px" }}
              >
                <p className="h6 text-black">
                  <strong>Fecha de evaluación</strong>
                </p>
              </div>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>

        {/* <div className='gap-3'>
                    <div className='h5 m-2 p-2 rounded text-danger' style={{backgroundColor: "yellow"}}><label htmlFor="">Fecha actual</label></div>
                    <div className='bg-success h5 m-2 p-2 text-light rounded'><label htmlFor="">Fecha de evaluación</label></div>                  
                </div>  */}
      </div>
      {/*********** sección de modales **********************/}
    </>
  );
};

export default ProximasEvaluacionesPage;
