import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageLogin from './pages/Login';
import PageLandingPage from './pages/LandingPage';
import ColaboradoresPage from './pages/GestionPage/ColaboradoresPage';
import FormatoEvaluacionPage from './pages/GestionPage/FormatoEvaluacionPage';
import ProximasEvaluacionesPage from './pages/Evaluaciones/ProximasEvaluacionesPage';
import AdministracionUsuariosExternosPage from './pages/AdministracionUsuariosExternosPage/ColaboradoresPage';
import AdministracionParametrosPage from './pages/AdministracionUsuariosExternosPage/ParametrosPage';
import RealizarEvaluacionesPage from './pages/Evaluaciones/RealizarEvaluacionesPage';
import ReporteEvaluacionesPage from './pages/ReporteEvaluacionesPage';
import FormatoEvaluacionesPage from './pages/FormatoEvaluacionPage';
import { Provider } from 'react-redux';
import Store from './pages/redux/store/Store';
import './App.css'


function App() {

  
  return (
    <Provider store={Store}>
      <BrowserRouter>      
        <Routes>
          <Route path="/" element={<PageLogin />}/>
          <Route path="/home" element={<PageLandingPage/>}/>
          <Route path="/gestionColaboradores" element={<ColaboradoresPage/>}/>
          <Route path="/gestionFormatoEvaluacion" element={<FormatoEvaluacionPage/>}/>    
          <Route path="/proximasEvaluaciones" element={<ProximasEvaluacionesPage/>}/>    
          <Route path="/realizarEvaluaciones" element={<RealizarEvaluacionesPage/>}/>                 
          <Route path="/admonUsuariosExternos" element={<AdministracionUsuariosExternosPage/>}/>              
          <Route path="/admonUsuariosParametros" element={<AdministracionParametrosPage/>}/>    
          <Route path="/reporteEvaluaciones" element={<ReporteEvaluacionesPage/>}/>      
          <Route path="/formatoEvaluaciones/:id/:date" element={<FormatoEvaluacionesPage/>}/>
        </Routes>              
      </BrowserRouter>      
    </Provider>
  );
}

export default App;
