import { Button} from "react-bootstrap";
import { FaCalendarCheck, FaChartLine, FaCheckDouble, FaClipboardCheck, FaFileAlt, FaForward, FaPeopleArrows, FaPowerOff, FaUserCheck, FaUsers} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import './style.css';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGOUT } from "../../pages/redux/store/Actions";
import { useParams } from 'react-router';


const logo = require('./digitalProf-samtel.jpeg');
const logo_png = require('./digitalProf-samtel.png');


const BarraMenu = () => {
    const params = useParams();
    const paramsLenght = Object.keys(params).length;
    const navigate = useNavigate();
    const emp: any = useSelector((state: any) => state.emp);
    const company: any = useSelector((state: any) => state.company);    

    const dispatch = useDispatch();

    const hndlrSalir = () =>{
        navigate('/');
        localStorage.clear()
        dispatch(USER_LOGOUT())
    }

    useEffect(()=>{

        if (emp.first_name === ""){
            navigate('/');
        }

    },[]);
    return (
        <nav className="navbar navbar-expand-lg  ">
            <div className="container-fluid shadow rounded"  style={{backgroundColor:"#9fcb4d"}}>
                {/* Imagén marca fluida */}
                {/* <div className="m-2" >
                    <a  href="#!">
                        <img src={logo} alt="" width={200}  className="img-fluid m-2 rounded"/>
                    </a>
                </div> */}
                <div className="m-2">
                    <a href="/home">
                        <div
                        className="image-container"
                        style={{
                            background: 'rgba(252, 252, 252, 0.60)', 
                            borderRadius: '10px', 
                            padding: '6px', 
                        }}
                        >
                        <img src={company && paramsLenght != 0 ? `${company}` : logo_png} alt="" width={200} className="img-fluid rounded" />
                        </div>
                    </a>
                </div>
                {/* Ícono de hamburguesa en modo responsive*/}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">                        

                        {/* Gestión de colaboradores*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" id="navbarDropdownDashboard" data-bs-toggle="dropdown" aria-expanded="false" key={0}
                                className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1  ${['Administrador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} 
                            > 
                                <FaChartLine /> Gestión
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">                               
                                <li><Link className={`dropdown-item  ${['Administrador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} to="/gestionColaboradores" ><FaPeopleArrows /> Colaboradores</Link></li>
                                <li><Link className={`dropdown-item  ${['Administrador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} to="/gestionFormatoEvaluacion" ><FaClipboardCheck /> Formato de evaluación</Link></li>
                            </ul>    
                        </li>

                        {/* Menú evaluaciones*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1  ${['Administrador', 'Evaluador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`}  id="navbarDropdownReferencia"  data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaCheckDouble />Evaluaciones
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReferencia">                               
                                <li><Link className={`dropdown-item   ${['Administrador', 'Evaluador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} to="/proximasEvaluaciones" ><FaForward /> Próximas evaluaciones</Link></li>
                                <li><Link className={`dropdown-item   ${['Administrador', 'Evaluador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} to="/realizarEvaluaciones" ><FaUserCheck /> Realizar evaluaciones</Link></li>
                            </ul>    
                        </li>

                        {/* Menú Inventario*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1  ${['Administrador', 'Evaluador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`}  id="navbarDropdownInventario"  data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaCalendarCheck /> Administración
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownInventario">                               
                                <li><Link className={`dropdown-item ${['Administrador', 'Evaluador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} to="/admonUsuariosExternos" ><FaUsers /> Usuarios</Link></li>                  
                                <li><Link className={`dropdown-item ${['Administrador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} to="/admonUsuariosParametros" ><FaClipboardCheck /> Parámetros</Link></li>                                                         
                            </ul>    
                        </li>

                        {/* Menú Consultas*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1  ${['Administrador', 'Evaluador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`}  id="navbarDropdownConsultas"  data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaFileAlt /> Reportes
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownConsultas">                               
                                <li><Link className={`dropdown-item  ${['Administrador', 'Evaluador'].find((pf:string)=>pf === emp.profile)? '':'disabled'}`} to="/reporteEvaluaciones" ><FaClipboardCheck /> Evaluaciones </Link></li>     
                            </ul>    
                        </li>

                    </ul>
                    <div className="d-flex flex-row gap-3 text-black wrap" style={{width:"fit-content"}}>
                        <div className="text-center">
                            <div className="">
                                Hola,
                            </div>
                            <div className=" text-dark fw-bold">
                            {emp.first_name} {emp.last_name}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="button"
                                className="border-0 button-normal" // Agrega la clase "button-normal"
                                onClick={hndlrSalir}
                            >
                                <FaPowerOff className="h3 mt-1"/>

                            </Button>
                        </div>
                    </div> 
                </div>  
            </div>
        </nav>        
    );
};

export default BarraMenu;