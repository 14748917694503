
import { Radar } from "react-chartjs-2";
import {  
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from "chart.js";
ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const RadarChart = (props: any) =>{

    const data = {
        labels: props.categories,
        datasets: [{
          label: props.seriesName, //'My First Dataset',
          data: props.seriesData, //[65, 59, 90, 81, 56, 55, 40],
          fill: true,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'green', //'rgb(255, 99, 132)',
          pointBackgroundColor: 'red',//'rgb(255, 99, 132)',
          pointBorderColor: 'red', //'#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)'
        }]
    };

    return (
        <div>
            <Radar 
                data={data}
                style={{height: "300px"}}
            />
        </div>
    );
}

export default RadarChart;