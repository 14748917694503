import BarraMenu from "../../components/BarraMenu";
import { useSelector } from "react-redux";
import {
  FaClipboardCheck,
} from "react-icons/fa";

import "./styles.css";
import { Link } from "react-router-dom";

const shrCut = require("./shortCut.png");

const PageLandingPage = () => {
  const emp: any = useSelector((state: any) => state.emp);

  return (
      <div className="container bg-white text-center">
        <BarraMenu />

        <div>
          <h2 className="mb-3 text-secondary">
            Accesos directos a opciones frecuentes{" "}
          </h2>
          <div className="floating-main">
            <Link
              className={`dropdown-item floating-main_icon ${
                ["Administrador", "Evaluador"].find(
                  (pf: string) => pf === emp.profile
                )
                  ? ""
                  : "disabled"
              }`}
              to="/reporteEvaluaciones"
            >
              <FaClipboardCheck />
              Evaluaciones
            </Link>
            <img
              className="floating-main_image"
              src={shrCut}
              alt="Accesos directos a opciones frecuentes"
            />
          </div>
        </div>
      </div>
  );
};

export default PageLandingPage;
