import BarraMenu from "../../../components/BarraMenu";
import { useEffect, useState } from "react";
import { httpApiPostText } from "../../../lib";
import { Alert, Button, Carousel, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { FaPencilAlt, FaPlusCircle, FaRegHandPaper } from "react-icons/fa";
import MsgDialog from "../../../components/MsgDialog";

const logo = require("../RealizarEvaluacionesPage/solid.jpg");

const RealizarEvaluacionesPage = () => {
  let [data, setData] = useState(new Array());
  const emp: any = useSelector((state: any) => state.emp);
  const [showRealizaEvalQuestion, setShowRealizaEvalQuestion] = useState(false);
  const [showEditaEvalQuestion, setShowEditaEvalQuestion] = useState(false);
  const [showEditaEval, setShowEditaEval] = useState(false);
  const [showRealizaEval, setShowRealizaEval] = useState(false);
  const [showMsgError, setShowMsgError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [pregunta, setPregunta] = useState("");
  const [nombreUsr, setNombreUsr] = useState("");
  let [idUsr, setIdUsr] = useState("");
  let [fecha, setFecha] = useState("");

  let columns = [
    {
      name: "Id",
      selector: (row: any) => row.id_user,
      sortable: true,
      width: "120px",
      omit: true,
    },
    {
      name: "Nombre",
      selector: (row: any) => row.name,
      sortable: true,
      width: "270px",
    },
    {
      name: "Cliente",
      selector: (row: any) => row.description,
      sortable: true,
      width: "260px",
    },
    {
      name: "Ingreso",
      selector: (row: any) => row.user_registered.substring(0, 10),
      sortable: true,
      width: "160px",
    },
    {
      name: "Evaluación",
      selector: (row: any) => row.user_evaluation_date.substring(0, 10),
      sortable: true,
      width: "160px",
    },
    { name: "Acciones", selector: (row: any) => row.Acciones, grow: 3 },
  ];

  const pagOptions = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const realizaEvaluacion = async (idUser: any, name: string, date: string) => {
    setNombreUsr(name);

    setIdUsr(idUser);
    setFecha(date);

    /*****************************
     *** Verifica si se puede hacer una evaluación nueva
     ***/
    const req = {
      valid: "validStartEvaluation",
      id_collaborator: `${idUser}`,
      id_evaluator: `${emp.id_user}`,
      date: `${date.substring(0, 10)}`,
    };
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      req
    );

    const arrDta = JSON.parse(response.message);
    if (arrDta.code === 1 || arrDta.code === 2) {
      setPregunta(
        `Se creará nueva evaluación del desempeño de ${name}. ¿Está usted seguro de continuar?`
      );
      setShowRealizaEvalQuestion(true);
    } else {
      setMsgError(
        "No se puede crear una evaluación nueva. Primero debe finalizar la que está abierta y en proceso de evaluación."
      );
      setShowMsgError(true);
    }
  };

  const editaEvaluacion = async (idUser: any, name: string, date: string) => {
    setNombreUsr(name);

    setIdUsr(idUser);

    setFecha(date);

    /************************************************************
     *** Verifica si se puede hacer una edición de una evaluación
     ************************************************************/
    const req = {
      valid: "validStartEvaluation",
      id_collaborator: `${idUser}`,
      id_evaluator: `${emp.id_user}`,
      date: `${date.substring(0, 10)}`,
    };
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      req
    );

    const arrDta = JSON.parse(response.message);
    if (arrDta.code === 3) {
      setPregunta(
        `A continuación la edición de la evaluación del desempeño de ${name}. ¿Está usted seguro de editarla?`
      );
      setShowEditaEvalQuestion(true);
    } else {
      setMsgError(
        "No se puede editar una evaluación porque no hay evaluación creada que esté en proceso de evaluación"
      );
      setShowMsgError(true);
    }
  };

  const formatoEvaluacion = () => {
    setShowRealizaEvalQuestion(false);
    setShowRealizaEval(true);
  };

  const formatoEdicionEvaluacion = () => {
    setShowEditaEvalQuestion(false);
    setShowEditaEval(true);
  };

  const preparaDatos = async () => {
    const req = {
      valid: "getCollaboratorsEvaluator",
      evaluatorUser: emp.id_user,
    };

    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      req
    );
    const dta = JSON.parse(response.message);
    if (dta.code === 1) {
      const newData = new Array();
      dta.payload.map((dta: any) => {
        const obj = {
          ...dta,
          Acciones: (
            <div className=" d-flex gap-3">
              <a
                href="#!"
                id={`${dta.id_user}`}
                onClick={() => {
                  realizaEvaluacion(
                    dta.id_user,
                    dta.name,
                    dta.user_evaluation_date
                  );
                }}
              >
                <FaPlusCircle
                  title="Realizar nueva evaluación"
                  color={"white"}
                  className="bg-success rounded-circle p-1 h4"
                />
              </a>
              <a
                href="#!"
                id={`${dta.id_user}`}
                onClick={() => {
                  editaEvaluacion(
                    dta.id_user,
                    dta.name,
                    dta.user_evaluation_date
                  );
                }}
              >
                <FaPencilAlt
                  title="Editar evaluación"
                  color={"black"}
                  className="bg-warning rounded-circle p-1 h4"
                />
              </a>
            </div>
          ),
        };
        newData.push(obj);
      });
      data = [...newData];
      setData([...data]);
    }
  };

  useEffect(() => {
    if (emp.first_name !== "") {
      preparaDatos();
    }
  }, []);

  const cerrarModalRealiza = () => {
    setShowRealizaEval(false);
  };

  // Modal para Realiazr evaluación nueva
  const ModalRealizaEvaluacion = (props: any) => {
    let [questions, setQuestions] = useState(new Array());
    const [comenzar, setComenzar] = useState(true);
    const [showFinalizalQuestion, setShowFinalizalQuestion] = useState(false);
    let indiceArr: number = 0;

    const getPreguntas = async () => {
      const req = {
        valid: "startEvaluationNew",
        id_collaborator: `${props.idColaborador}`,
        id_evaluator: `${emp.id_user}`,
        date: `${fecha.substring(0, 10)}`,
      };

      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      const response = await httpApiPostText(
        url,
        "POST",
        {
          "Content-Type": "application/json",
        },
        req
      );

      const dta = JSON.parse(response.message);
      if (dta.code === 0) {
        setShowRealizaEval(false);
        setMsgError(dta.message);
        setShowMsgError(true);
      } else {
        questions = [];
        dta.payload.map((obj: any) => questions.push(obj));
        questions.sort(
          (objx: any, objy: any) => objx.id_categorie - objy.id_categorie
        );
        setQuestions([...questions]);
      }
    };

    const HandlerClickComenzar = () => {
      setComenzar(false);
      getPreguntas();
    };

    const guardarPregunta = async (idx: number) => {
      const nmSel = `quailify-${questions[idx].question_id}-${idx}`;
      const nmTxt = `qst-${questions[idx].question_id}-${idx}`;
      const req = {
        valid: "updateQuestion",
        id_log: questions[idx].id,
        question_id: questions[idx].id,
        user_id: questions[idx].user_id,
        evaluator_id: questions[idx].evaluator_id,
        evaluated_range: getElement(nmSel) === null ? 0 : getElement(nmSel),
        feedback: getElement(nmTxt) === null ? "" : getElement(nmTxt),
        make_evaluation_id: emp.id_user,
      };
      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      await httpApiPostText(
        url,
        "PUT",
        {
          "Content-Type": "application/json",
        },
        req
      );
    };

    const OnClickGuardarSalir = () => {
      guardarPregunta(indiceArr);
      cerrarModalRealiza();
    };

    const finalizaEvaluacion = async () => {
      guardarPregunta(indiceArr);

      const req = {
        valid: "updateDateEvaluation",
        user_id: `${props.idColaborador}`,
        evaluator_id: `${emp.id_user}`,
        date: `${fecha.substring(0, 10)}`,
      };

      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      await httpApiPostText(
        url,
        "PUT",
        {
          "Content-Type": "application/json",
        },
        req
      );
      setShowFinalizalQuestion(false);
      cerrarModalRealiza();
    };

    const OnClickFinalizarSalir = async () => {
      setShowFinalizalQuestion(true);
    };

    const getElement = (id: string) => {
      const input = document.getElementById(id) as HTMLInputElement | null;

      if (input != null) {
        return input.value;
      }

      return null;
    };

    const setElement = (id: string, value: string) => {
      const input = document.getElementById(id) as HTMLInputElement | null;

      if (input != null) {
        input.value = value;
      }
    };

    // componente carrousell
    const ControlledCarousel2 = (props: any) => {
      const [index, setIndex] = useState(0);
      const [showMsgErrorCalificacion, setShowMsgErrorCalificacion] =
        useState(false);
      let [forms, setForms] = useState({});

      const guardarPregunta = async (idx: number) => {
        const req = {
          valid: "updateQuestion",
          id_log: questions[idx].id,
          question_id: questions[idx].question_id,
          user_id: questions[idx].user_id,
          evaluator_id: questions[idx].evaluator_id,
          evaluated_range:
            questions[idx].evaluated_range === null
              ? 0
              : questions[idx].evaluated_range,
          feedback:
            questions[idx].feedback === null ? "" : questions[idx].feedback,
          make_evaluation_id: emp.id_user,
        };
        const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
        await httpApiPostText(
          url,
          "PUT",
          {
            "Content-Type": "application/json",
          },
          req
        );
      };

      const carousellSelect = (selectedIndex: number) => {
        let idxArr = index; // indice pregunta anterior
        indiceArr = selectedIndex; // indice pregunta actual.
        setIndex(selectedIndex);

        const nmSel = `quailify-${props.preguntas[idxArr].question_id}-${idxArr}`; // id de calificación
        const nmTxt = `qst-${props.preguntas[idxArr].question_id}-${idxArr}`; // id de comantario

        if (props.preguntas[idxArr].minimum < props.preguntas[idxArr].maximum) {
          questions[idxArr].evaluated_range = getElement(nmSel);
        }
        questions[idxArr].feedback = getElement(nmTxt);

        forms = { ...forms, [nmTxt]: getElement(nmTxt) };
        setForms({ ...forms });

        guardarPregunta(idxArr);
      };

      const onSelectorBlur = (evnt: any) => {
        const val = parseInt(evnt.target.value);
        const min = parseInt(evnt.target.min);
        const max = parseInt(evnt.target.max);

        if (evnt.target.value === "") {
          // no hacer nada, por ahora
          setShowMsgErrorCalificacion(false);
        } else if (val < min || val > max) {
          setShowMsgErrorCalificacion(true);
          const nmSlt = evnt.target.id;
          setElement(nmSlt, "");
        }
      };

      useEffect(() => {
        const updateQuestions = () => {
          if (props.preguntas.length > 0) {
            // proceso de actualizar los elementos de las pregntas con lo que está guardado en BD
            props.preguntas.map((qst: any, idx: number) => {
              const nmSel = `quailify-${qst.question_id}-${idx}`;
              const nmTxt = `qst-${qst.question_id}-${idx}`;

              let evr: string = "0.0";
              if (qst.evaluated_range !== null && qst.evaluated_range !== "") {
                evr = parseFloat(qst.evaluated_range).toFixed(2);
                setElement(nmSel, evr);
              }
              setElement(nmTxt, qst.feedback || "");
            });
          }
        };

        updateQuestions();
      }, []);

      return (
        <Carousel onSelect={carousellSelect} interval={null} wrap={false}>
          {props.preguntas.map((obj: any, idx: number) => (
            <Carousel.Item key={obj.id}>
              <img
                className="d-block w-100"
                src={logo}
                alt={`Pregunta ${idx}`}
                height={"400px"}
              />
              <Carousel.Caption className="text-dark">
                <h3 className="mb-3">{obj.categorie}</h3>
                <h5>{obj.title}</h5>
                <p>{obj.description}</p>
                <div className="">
                  {obj.minimum < obj.maximum ? (
                    <div>
                      <div className="d-flex flex-column justify-content-start p-2">
                        <input
                          type="number"
                          id={`quailify-${obj.question_id}-${idx}`}
                          placeholder={`Califique entre ${obj.minimum} y ${obj.maximum} `}
                          className="form-control p-2"
                          min={`${obj.minimum}`}
                          max={`${obj.maximum}`}
                          pattern="^[1-9]\d*$"
                          step={0.1}
                          onBlur={onSelectorBlur}
                          onFocus={() => setShowMsgErrorCalificacion(false)}
                        />
                      </div>
                      <Alert variant="danger" show={showMsgErrorCalificacion}>
                        <FaRegHandPaper /> Valor de la calificación fuera de los
                        valores permitidos. Este valor no será guardado.
                      </Alert>
                    </div>
                  ) : null}
                  <div className="d-flex flex-column justify-content-center">
                    <input
                      type="text"
                      id={`qst-${obj.question_id}-${idx}`}
                      placeholder="Observación"
                      className="form-control p-2"
                    />
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      );
    };

    return (
      <>
        <Modal
          show={props.Show}
          centered={true}
          size="xl"
          onHide={props.handlerClickEnd}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="h3 text-center">
              Formato de evaluación de {props.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "450px" }}>
            <div className="d-flex ">
              {comenzar ? (
                <div
                  className="d-flex align-items-center  justify-content-center w-100"
                  style={{ height: "450px" }}
                >
                  <Button
                    type="button"
                    className="btn btn-success "
                    onClick={HandlerClickComenzar}
                  >
                    {" "}
                    Comenzar{" "}
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-center w-100">
                  <ControlledCarousel2
                    idColaborador={props.idColaborador}
                    preguntas={questions}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {comenzar ? (
              <Button
                type="button"
                className="btn btn-success "
                onClick={props.HandlerClickOk}
              >
                {" "}
                Salir{" "}
              </Button>
            ) : (
              <div className="d-flex justify-content-between w-50">
                <Button
                  type="button"
                  className="btn btn-success btnColorOk"
                  onClick={OnClickGuardarSalir}
                >
                  {" "}
                  Guardar y salir{" "}
                </Button>
                <Button
                  type="button"
                  className="btn btn-danger "
                  onClick={OnClickFinalizarSalir}
                >
                  {" "}
                  Finalizar evaluación{" "}
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* Cuadro de dialogo de finalización de evaluación*/}
        <MsgDialog
          Show={showFinalizalQuestion}
          Title="Evaluación desempeño"
          Message={
            "Se va a finalizar el proceso de evaluación, una vez finalizada ya no podrá modificar la información en ella. ¿Está usted seguro de hacerlo?"
          }
          BtnOkName="Si, continuar"
          BtnNokName="No, cancelar"
          HanlerdClickNok={() => setShowFinalizalQuestion(false)}
          HandlerClickOk={finalizaEvaluacion}
        />
      </>
    );
  };

  const cerrarModal = () => {
    setShowEditaEval(false);
  };

  // Modal para editar evaluación ya creada
  const ModalEditaEvaluacion = (props: any) => {
    let [questions, setQuestions] = useState(new Array());
    const [comenzar, setComenzar] = useState(true);
    const [showFinalizalQuestion, setShowFinalizalQuestion] = useState(false);
    let indiceArr: number = 0;

    const getPreguntas = async () => {
      const req = {
        valid: "startEvaluation",
        id_collaborator: `${props.idColaborador}`,
        id_evaluator: `${emp.id_user}`,
        date: `${fecha.substring(0, 10)}`,
      };

      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      const response = await httpApiPostText(
        url,
        "POST",
        {
          "Content-Type": "application/json",
        },
        req
      );

      const dta = JSON.parse(response.message);
      if (dta.code < 2) {
        setMsgError(
          "Se ha presentado un problema en la BD. Contacte al administrador"
        );
        setShowMsgError(true);
      } else {
        questions = [];
        dta.payload.map((obj: any) => questions.push(obj));
        questions.sort(
          (objx: any, objy: any) => objx.id_categorie - objy.id_categorie
        );
        setQuestions([...questions]);
      }
    };

    const HandlerClickComenzar = () => {
      setComenzar(false);
      getPreguntas();
    };

    const guardarPregunta = async (idx: number) => {
      const nmSel = `quailify-${questions[idx].question_id}-${idx}`;
      const nmTxt = `qst-${questions[idx].question_id}-${idx}`;
      const req = {
        valid: "updateQuestion",
        id_log: questions[idx].id,
        question_id: questions[idx].id,
        user_id: questions[idx].user_id,
        evaluator_id: questions[idx].evaluator_id,
        evaluated_range: getElement(nmSel) === "" ? 0 : getElement(nmSel),
        feedback: getElement(nmTxt) === "" ? "" : getElement(nmTxt),
        make_evaluation_id: emp.id_user,
      };

      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      await httpApiPostText(
        url,
        "PUT",
        {
          "Content-Type": "application/json",
        },
        req
      );
    };

    const OnClickGuardarSalir = () => {
      guardarPregunta(indiceArr);
      cerrarModal();
    };

    const finalizaEvaluacion = async () => {
      guardarPregunta(indiceArr);

      const req = {
        valid: "updateDateEvaluation",
        user_id: `${props.idColaborador}`,
        evaluator_id: `${emp.id_user}`,
        date: `${fecha.substring(0, 10)}`,
      };

      const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
      await httpApiPostText(
        url,
        "PUT",
        {
          "Content-Type": "application/json",
        },
        req
      );
      setShowFinalizalQuestion(false);
      cerrarModal();
    };

    const OnClickFinalizarSalir = async () => {
      setShowFinalizalQuestion(true);
    };

    const getElement = (id: string) => {
      const input = document.getElementById(id) as HTMLInputElement | null;

      if (input != null) {
        return input.value;
      }

      return "";
    };

    const setElementEdit = (id: string, value: string) => {
      const inputEdit = document.getElementById(id) as HTMLInputElement | null;

      if (inputEdit != null) {
        inputEdit.value = value;
      }
    };

    const ControlledCarousel = (props: any) => {
      const [index, setIndex] = useState(0);
      const [showMsgErrorCalificacion, setShowMsgErrorCalificacion] =
        useState(false);
      let [forms, setForms] = useState({});

      const guardarPregunta = async (idx: number) => {
        const req = {
          valid: "updateQuestion",
          id_log: questions[idx].id,
          question_id: questions[idx].question_id,
          user_id: questions[idx].user_id,
          evaluator_id: questions[idx].evaluator_id,
          evaluated_range:
            questions[idx].evaluated_range === ""
              ? 0
              : questions[idx].evaluated_range,
          feedback:
            questions[idx].feedback === "" ? "" : questions[idx].feedback,
          make_evaluation_id: emp.id_user,
        };
        const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
        await httpApiPostText(
          url,
          "PUT",
          {
            "Content-Type": "application/json",
          },
          req
        );
      };

      const carousellSelect = (selectedIndex: number, e: any) => {
        setShowMsgErrorCalificacion(false);
        let idxArr = index;
        indiceArr = selectedIndex;
        setIndex(selectedIndex);

        const nmSel = `quailify-${props.preguntas[idxArr].question_id}-${idxArr}`;
        const nmTxt = `qst-${props.preguntas[idxArr].question_id}-${idxArr}`;

        questions[idxArr].feedback = getElement(nmTxt);
        questions[idxArr].evaluated_range = getElement(nmSel);

        forms = { ...forms, [nmTxt]: getElement(nmTxt) };
        setForms({ ...forms });

        guardarPregunta(idxArr);
      };

      const onSelectorBlur = (evnt: any) => {
        const valSelector = parseInt(evnt.target.value);
        const minSelector = parseInt(evnt.target.min);
        const maxSelector = parseInt(evnt.target.max);

        if (evnt.target.value === "") {
          // no hacer nada, por ahora
          setShowMsgErrorCalificacion(false);
        } else if (valSelector < minSelector || valSelector > maxSelector) {
          setShowMsgErrorCalificacion(true);
          const nmSlt = evnt.target.id;
          setElementEdit(nmSlt, "");
        }
      };

      useEffect(() => {
        const updateQuestions = () => {
          if (props.preguntas.length > 0) {
            // proceso de actualizar los elementos de las pregntas con lo que está guardado en BD
            props.preguntas.map((qst: any, idx: number) => {
              const nmSel = `quailify-${qst.question_id}-${idx}`;
              const nmTxt = `qst-${qst.question_id}-${idx}`;

              let evr: string = "0.0";
              if (qst.evaluated_range !== null && qst.evaluated_range !== "") {
                evr = parseFloat(qst.evaluated_range).toFixed(2);
                setElementEdit(nmSel, evr);
              }
              setElementEdit(nmTxt, qst.feedback || "");
            });
          }
        };

        updateQuestions();
      }, []);

      return (
        <Carousel onSelect={carousellSelect} interval={null} wrap={false}>
          {props.preguntas.map((obj: any, idx: number) => (
            <Carousel.Item key={obj.id}>
              <img
                className="d-block w-100"
                src={logo}
                alt={`Pregunta ${idx}`}
                height={"400px"}
              />
              <Carousel.Caption className="text-dark">
                <h3 className="mb-3">{obj.categorie}</h3>
                <h5>{obj.title}</h5>
                <p>{obj.description}</p>
                <div className="">
                  {obj.minimum < obj.maximum ? (
                    <div>
                      <div className="d-flex flex-column justify-content-start">
                        <input
                          type="number"
                          id={`quailify-${obj.question_id}-${idx}`}
                          placeholder={`Califique entre ${obj.minimum} y ${obj.maximum} `}
                          className="form-control p-2"
                          min={obj.minimum}
                          max={obj.maximum}
                          pattern="^[1-9]\d*$"
                          step={0.1}
                          onBlur={onSelectorBlur}
                          onFocus={() => setShowMsgErrorCalificacion(false)}
                        />
                      </div>
                      <Alert variant="danger" show={showMsgErrorCalificacion}>
                        <FaRegHandPaper /> Valor de la calificación fuera de los
                        valores permitidos. Este valor no será guardado.
                      </Alert>
                    </div>
                  ) : null}
                  <div className="d-flex flex-column justify-content-center mt-1">
                    <input
                      type="text"
                      id={`qst-${obj.question_id}-${idx}`}
                      placeholder="Observación"
                      className="form-control p-2"
                    />
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      );
    };

    return (
      <>
        <Modal
          show={props.Show}
          centered={true}
          size="xl"
          onHide={props.handlerClickEnd}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="h3 text-center">
              Formato de evaluación de {props.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "450px" }}>
            <div className="d-flex ">
              {comenzar ? (
                <div
                  className="d-flex align-items-center  justify-content-center w-100"
                  style={{ height: "450px" }}
                >
                  <Button
                    type="button"
                    className="btn btn-success "
                    onClick={HandlerClickComenzar}
                  >
                    {" "}
                    Comenzar{" "}
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-center w-100">
                  <ControlledCarousel
                    idColaborador={props.idColaborador}
                    preguntas={questions}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="">
            {comenzar ? (
              <Button
                type="button"
                className="btn btn-success btnColorOk"
                onClick={props.HandlerClickOk}
              >
                {" "}
                Salir{" "}
              </Button>
            ) : (
              <div className="d-flex justify-content-between w-50">
                <div className="w-50">
                  <Button
                    type="button"
                    className="btn btn-success "
                    onClick={OnClickGuardarSalir}
                  >
                    {" "}
                    Guardar y salir{" "}
                  </Button>{" "}
                </div>
                <div className="w-50">
                  <Button
                    type="button"
                    className="btn btn-danger "
                    onClick={OnClickFinalizarSalir}
                  >
                    {" "}
                    Finalizar evaluación{" "}
                  </Button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* Cuadro de dialogo de finalización de evaluación*/}
        <MsgDialog
          Show={showFinalizalQuestion}
          Title="Evaluación desempeño"
          Message={
            "Se va a finalizar el proceso de evaluación, una vez finalizada ya no podrá modificar la información en ella. ¿Está usted seguro de hacerlo?"
          }
          BtnOkName="Si, continuar"
          BtnNokName="No, cancelar"
          HanlerdClickNok={() => setShowFinalizalQuestion(false)}
          HandlerClickOk={finalizaEvaluacion}
        />
      </>
    );
  };

  return (
    <div className="container bg-white">
      <BarraMenu />
      <div>
        <div className="text-secondary ms-3 mt-3 mb-3 fw-bold">
          Home / Evaluación / Realizar evaluaciones
        </div>
        <form>
          <div className="row shadow rounded mt-3 mb-3">
            <p>Listado de evaluados</p>
            <DataTable
              columns={columns}
              data={data}
              selectableRowsHighlight
              fixedHeader
              pagination
              highlightOnHover
              paginationComponentOptions={pagOptions}
            />
          </div>
        </form>
      </div>
      {/* Cuadro de dialogo Realiazar nueva evaluación desempeño */}
      <MsgDialog
        Show={showRealizaEvalQuestion}
        Title="Evaluación desempeño"
        Message={pregunta}
        BtnOkName="Si, continuar"
        BtnNokName="No, cancelar"
        HanlerdClickNok={() => setShowRealizaEvalQuestion(false)}
        HandlerClickOk={formatoEvaluacion}
      />
      {/* Cuadro de dialogo edita evaluación desempeño ya creada */}
      <MsgDialog
        Show={showEditaEvalQuestion}
        Title="Evaluación desempeño"
        Message={pregunta}
        BtnOkName="Si, continuar"
        BtnNokName="No, cancelar"
        HanlerdClickNok={() => setShowEditaEvalQuestion(false)}
        HandlerClickOk={formatoEdicionEvaluacion}
      />
      {/**************************
       ****Formato de edición de evaluación
       ***************************/}
      <ModalEditaEvaluacion
        Show={showEditaEval}
        name={nombreUsr}
        idColaborador={idUsr}
        HandlerClickOk={cerrarModal}
        handlerClickEnd={cerrarModal}
      />
      {/**************************
       ****Formato de realización de evaluación
       ***************************/}
      <ModalRealizaEvaluacion
        Show={showRealizaEval}
        name={nombreUsr}
        idColaborador={idUsr}
        HandlerClickOk={cerrarModalRealiza}
        handlerClickEnd={cerrarModalRealiza}
      />

      <MsgDialog
        Show={showMsgError}
        Title="Evaluación desempeño"
        Message={msgError}
        BtnOkName="Aceptar"
        BtnNokName=""
        HanlerdClickNok={null}
        HandlerClickOk={() => setShowMsgError(false)}
      />
    </div>
  );
};

export default RealizarEvaluacionesPage;
