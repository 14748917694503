
export async function httpApiPostText(url: string, metodo: string, cabeceras: any, bd: any) {
    
    try {
        const response = await fetch(url, {
                method: metodo,
                headers: cabeceras,
                body: JSON.stringify(bd)
        });        
        const data = await response.text();
        return ({code: response.status, message: data});
    }
    catch(e){
        console.log(" Error: ", e); 
        return ({code: 400, message: "Error en la petición api"});
    }
}


export async function httpApiGetText(url: string, metodo: string, cabeceras: any) {
    
    try {
        console.log(url, metodo, cabeceras)
        const response = await fetch(url, {
                method: metodo,
                headers: cabeceras,
        });         
        const data = await response.text();
        return ({code: response.status, message: data});
    }
    catch(e){
        console.log(" Error: ", e); 
        return ({code: 400, message: "Error en la petición api"});
    }
}