import { FaTrashAlt } from "react-icons/fa";
import BarraMenu from "../../../components/BarraMenu";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { httpApiPostText } from "../../../lib";
import MsgDialog from "../../../components/MsgDialog";

const FormatoEvaluacionPage = () => {
  let [data, setData] = useState(new Array());
  let [cpData, setCpData] = useState(new Array());
  let [formData, setFormData] = useState({
    description: "",
    minimum: "",
    maximum: "",
    title: "",
    category_id: "",
    state_type: "1",
    type: "",
  });
  const [respuestaShow, setRespuestaShow] = useState(false);
  const [respuestaMsg, setRespuestaMsg] = useState("");
  let [categorias, setCategorias] = useState(new Array());
  const [borarShow, setBorarShow] = useState(false);
  const [msgActivacion, setMsgActivacion] = useState("");
  const [preguntaObj, setPreguntaObj] = useState({
    id: "",
    pregunta: "",
    rangoF: "",
    rangoI: "",
    categoria: "",
  });

  let columns = [
    {
      name: "Categoria",
      selector: (row: any) => row.categoria,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Título",
      selector: (row: any) => row.title,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Pregunta",
      selector: (row: any) => row.description,
      sortable: true,
      width: "310px",
      wrap: true,
    },
    {
      name: "Tipo",
      selector: (row: any) => row.type,
      sortable: true,
      width: "110px",
    },
    {
      name: "Min.",
      selector: (row: any) => row.minimum,
      sortable: true,
      width: "80px",
      right: true,
    },
    {
      name: "Máx.",
      selector: (row: any) => row.maximum,
      sortable: true,
      width: "80px",
      right: true,
    },
    {
      name: "Estado",
      selector: (row: any) => row.states,
      right: true,
      width: "90px",
      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row: any) => row.Acciones,
      right: true,
      width: "100px",
      sortable: true,
    },
  ];

  const pagOptions = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const conditionalRowStyles = [
    {
      when: (row: { states: string }) => row.states === "inactivo",
      style: {
        backgroundColor: "#D5D5D5",
      },
    },
  ];

  const getPreguntas = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "selectAllQuestion" }
    );
    const dta = JSON.parse(response.message);
    const newData = new Array();
    dta.payload.map((dta: any) => {
      const obj = {
        ...dta,
        Acciones: (
          <div className=" d-flex gap-1">
            <a
              href="#!"
              id={`${dta.id}`}
              onClick={() => {
                onDesactivar(dta);
              }}
            >
              <FaTrashAlt
                title="Elimina pregunta"
                color={"white"}
                className="bg-danger rounded-circle p-1 h4"
              />
            </a>
          </div>
        ),
      };
      newData.push(obj);
    });
    data = [...newData];
    setData([...data]);
    cpData = [...newData];
    setCpData([...cpData]);
  };

  const getCategorias = async () => {
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "POST",
      {
        "Content-Type": "application/json",
      },
      { valid: "selectCategories" }
    );
    const dta = JSON.parse(response.message);
    setCategorias(dta.payload);
  };

  const guardar = async (e: any) => {
    e.preventDefault();

    let sw: number = 0;
    sw = formData.title === "" ? ++sw : sw;
    sw = formData.category_id === "" ? ++sw : sw;
    sw = formData.description === "" ? ++sw : sw;
    sw = formData.minimum === "" ? ++sw : sw;
    sw = formData.maximum === "" ? ++sw : sw;

    const vf = parseInt(formData.maximum);
    const vi = parseInt(formData.minimum);

    if (vi < 0 || vf < 0) {
      setRespuestaMsg(
        "Loa valores de los rangos no pueden ser valores menores a 0. Por favor corregir"
      );
      setRespuestaShow(true);
    } else if (sw === 0) {
      if (vi <= vf) {
        const bdy = {
          ...formData,
          valid: "newQuestion",
        };
        const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
        const response = await httpApiPostText(
          url,
          "POST",
          {
            "Content-Type": "application/json",
          },
          bdy
        );
        const dta = JSON.parse(response.message);
        setRespuestaMsg(dta.message);
        setRespuestaShow(true);

        if (dta.code === 1) {
          formData = {
            description: "",
            minimum: "",
            maximum: "",
            title: "",
            category_id: "",
            state_type: "",
            type: "",
          };
          setFormData({ ...formData });
          getPreguntas();
        }
      } else {
        setRespuestaMsg(
          "El rango final nunca puede ser menor que el rangfo incial"
        );
        setRespuestaShow(true);
      }
    } else {
      setRespuestaMsg(
        "Todos los campos son obligatorios y los rangos deben ser validos, por tanto deben estar todos digitados."
      );
      setRespuestaShow(true);
    }
  };

  const changeTextFiltro = (evnt: any) => {
    if (evnt.target.value.trim() === "") {
      setData([...cpData]);
    } else {
      let reg = cpData.filter((dato: any) => {
        return (
          dato?.categoria
            .toUpperCase()
            .includes(evnt.target.value.toUpperCase()) ||
          dato?.title.toUpperCase().includes(evnt.target.value.toUpperCase()) ||
          dato?.description
            .toUpperCase()
            .includes(evnt.target.value.toUpperCase()) ||
          dato?.type.toUpperCase().includes(evnt.target.value.toUpperCase())
        );
      });
      setData(reg);
    }
  };

  const textChange = async (evnt: any) => {
    formData = { ...formData, [evnt.target.id]: evnt.target.value };
    setFormData({ ...formData });
  };

  const onDesactivar = (dta: any) => {
    setPreguntaObj(dta);
    setMsgActivacion(
      "¿Está usted seguro de querer activar/desactivar la pregunta?"
    );
    setBorarShow(true);
  };

  const borrarSi = async () => {
    const nObj = {
      valid: "deleteQuestion",
      id: preguntaObj.id,
      state_type: 0,
    };

    setBorarShow(false);
    const url = `https://${process.env.REACT_APP_API_URL_PORT_BACKEND}/evaluacion/`;
    const response = await httpApiPostText(
      url,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      nObj
    );

    const dta = JSON.parse(response.message);
    setRespuestaMsg(dta.message);
    setRespuestaShow(true);

    // actualiza la grilla
    getPreguntas();
  };

  const borrarNo = () => {
    setBorarShow(false);
  };

  useEffect(() => {
    getCategorias();
    getPreguntas();
  }, []);

  return (
    <>
      <div className="container bg-white">
        <BarraMenu />
        <div>
          <div className="text-dark ms-3 mt-3 fw-bold">
            Home / Gestión / Formato evaluación
          </div>
        </div>
        <div className="border border-1 shadow rounded mt-3 mb-3">
          <form
            action=""
            className=" border border-1 rounded justify-content-center mx-auto"
          >
            <div className="container row ">
              <div className=" col-lg-6 d-flex flex-column m-2">
                <label htmlFor="title" className=" form-label fw-bold">
                  Titulo
                </label>
                <input
                  className="form-control"
                  id="title"
                  value={formData.title}
                  onChange={textChange}
                />
              </div>

              <div className=" col-lg-5 col-md-12  d-flex flex-column m-2">
                <label htmlFor="category_id" className="form-label fw-bold">
                  Categoría
                </label>
                <select
                  id="category_id"
                  className="form-select"
                  value={formData.category_id}
                  onChange={textChange}
                >
                  <option value="">Seleccione un cargo...</option>
                  {categorias.map((dt: any, index: number) => (
                    <option value={dt.id} key={dt.id}>
                      {dt.description}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" col-lg-6 col-md-12  d-flex flex-column  m-2">
                <label htmlFor="description" className="form-label fw-bold">
                  Pregunta
                </label>
                <textarea
                  name=""
                  id="description"
                  cols={40}
                  rows={3}
                  className="form-control"
                  value={formData.description}
                  onChange={textChange}
                ></textarea>
              </div>

              <div className=" col-lg-5 col-md-12 m-2 ">
                <p className="form-label fw-bold">
                  Rango calificación (0-0 para Caulitativa)
                </p>
                <div className="row">
                  <div className="d-flex flex-column w-50">
                    <label htmlFor="minimum" className="form-label">
                      Inicial
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="minimum"
                      min={0}
                      pattern="^[1-9]\d*$"
                      value={formData.minimum}
                      onChange={textChange}
                    />
                  </div>
                  <div className="d-flex flex-column w-50">
                    <label htmlFor="maximum" className="form-label">
                      Final
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="maximum"
                      min={0}
                      pattern="^[1-9]\d*$"
                      value={formData.maximum}
                      onChange={textChange}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end col-lg-12 m-2 ">
                <button className="btn btn-success m-3" onClick={guardar}>
                  {" "}
                  Agregar{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div
          className="border shadow rounded mt-3 mb-3"
          style={{ backgroundColor: "#DCDCDC" }}
        >
          <div className="d-flex flex-column col col-lg-3 m-3 ">
            <label htmlFor="txtBuscar" className="m-1">
              Busqueda
            </label>
            <input
              type="text"
              id="txtBuscar"
              className="form-control m-1"
              onChange={changeTextFiltro}
            />
          </div>
          <DataTable
            columns={columns}
            data={data}
            selectableRowsHighlight
            fixedHeader
            pagination
            highlightOnHover
            paginationComponentOptions={pagOptions}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
      {/************Modal cosnulta para desactivar colaboradores**********/}
      <MsgDialog
        Show={respuestaShow}
        Title={`Formato evaluación`}
        Message={respuestaMsg}
        BtnOkName="Aceptar"
        BtnNokName=""
        HandlerClickOk={() => setRespuestaShow(false)}
        HanlerdClickNok={null}
      />

      {/************Modal cosnulta para borrar prguntas**********/}
      <MsgDialog
        Show={borarShow}
        Title={`Formato evaluación`}
        Message={msgActivacion}
        BtnOkName="Sí, continuar"
        BtnNokName="No, cancelar"
        HandlerClickOk={() => borrarSi()}
        HanlerdClickNok={borrarNo}
      />
    </>
  );
};

export default FormatoEvaluacionPage;
