const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ??  '') : "";
const hasUser = user !== "";

export const  initalState = {
    emp: {
        first_name: hasUser ? user.user.first_name : "",
        id_user: hasUser ? user.user.id_user : "",
        last_name: hasUser ? user.user.last_name : "",
        profile: hasUser ?  user.user.profile : "",
        token: hasUser ? user.token : "",
        },
        company: ""
};

const Reducer = (state = initalState, action: any) => {
    switch(action.type){
        case "SetEntornoEmp" : {
            return {...state, emp: action.emp};
        }
        case "USER_LOGOUT" : {
            return {...state};
        }
        case "GetEntornoEmp" : {
            return {...state};
        }                      
        case "GET_COMPANY_LOGO" : {
            return {...state, company: action.company };
        }                      
        default: return state;
    }
}

export default Reducer;